export const tableColumns = [
    {
        title: '',
        align: 'center',
        type: 'selection',
        width: 60,
        visible: true,
    },
    {
        title: '序号',
        align: 'center',
        type: 'index',
        slots: { customRender: 'indexNumber' },
        width: 60,
        visible: true,
    },
    {
        title: '被访人',
        align: 'center',
        width: 160,
        key: 'selfName',
        visible: true,
    },
    {
        title: '被访人手机',
        align: 'center',
        width: 160,
        key: 'selfPhone',
        visible: true,
    },
    {
        title: '开始访问时间',
        align: 'center',
        width: 150,
        key: 'startTime',
        visible: true,
    },
    {
        title: '结束访问时间',
        align: 'center',
        width: 150,
        key: 'endTime',
        visible: true,
    },
    {
        title: '来访人',
        align: 'center',
        width: 120,
        key: 'userName',
        visible: true,
    },
    {
        title: '来访手机',
        align: 'center',
        width: 120,
        key: 'phone',
        visible: true,
    },
    {
        title: '来访人数',
        align: 'center',
        width: 100,
        key: 'personNum',
        visible: true,
    },
    {
        title: '类别',
        align: 'center',
        width: 120,
        key: 'phone',
        slots: { customRender: 'level' },
        visible: false,
    },
    {
        title: '状态',
        align: 'center',
        width: 120,
        key: 'orderState',
        slots: { customRender: 'orderState' },
        visible: true,
    },
    {
        title: '审批人',
        align: 'center',
        width: 120,
        key: 'approverName',
        visible: true,
    },
    {
        title: '设备名称',
        align: 'center',
        width: 120,
        key: 'name',
        visible: true,
    },
    {
        title: '设备SN号',
        align: 'center',
        width: 160,
        key: 'sn',
        visible: true,
    },
    {
        title: '申请时间',
        align: 'center',
        width: 150,
        key: 'createTime',
        visible: true,
    },
    {
        title: '操作',
        fixed: 'right',
        align: 'center',
        slots: { customRender: 'operate' },
        width: 140,
        key: 'imei',
        visible: true,
    },
];
