import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_tree_select = _resolveComponent("el-tree-select");

  return _openBlock(), _createBlock(_component_el_tree_select, {
    modelValue: _ctx.treeName,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.treeName = $event;
    }),
    ref: "tree",
    "node-key": "id",
    data: _ctx.treeData,
    props: _ctx.defaultProps,
    onCheck: _ctx.treeCheckChange,
    onNodeClick: _ctx.handleNodeClick,
    "show-checkbox": _ctx.showCheckbox
  }, null, 8, ["modelValue", "data", "props", "onCheck", "onNodeClick", "show-checkbox"]);
}