import { computed } from 'vue';
import BSJOrg from '@/components/BSJOrg/index.vue';
export default {
  name: 'organization',
  props: {
    // 开关
    visible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BSJOrg: BSJOrg
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var show = computed({
      get: function get() {
        return props.visible;
      },
      set: function set(val) {
        emit('update:visible', val);
      }
    }); // 返回

    var onClose = function onClose() {
      emit('update:visible', false);
    };

    return {
      show: show,
      onClose: onClose
    };
  }
};