import { computed, defineComponent } from 'vue';
import { isArray } from '@/utils/is';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    // 当前类型
    type: {
      type: String
    },
    // 提示文本
    text: {
      type: String,
      default: '请选择'
    },
    // 组件尺寸
    size: {
      type: String,
      default: ''
    },
    // 是否可清空
    clearable: {
      type: Boolean,
      default: false
    },
    // 是否可清空
    filterable: {
      type: Boolean,
      default: false
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    // 只显示文本
    isText: {
      type: Boolean,
      default: false
    },
    // 是否禁用
    isDisabled: {
      type: Boolean,
      default: false
    },
    //model值
    modelValue: [Number, String, Array]
  },
  emits: ['update:modelValue', 'handleChange', 'onRemoveTag'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useStore = useStore(),
        state = _useStore.state;

    var type = props.type; // 客户代码

    var customCode = computed(function () {
      return state.userInfo.customCode;
    });
    var list = computed(function () {
      switch (type) {
        case 'post':
          // 职位
          return [{
            value: 1,
            label: '经理'
          }, {
            value: 2,
            label: '主管'
          }, {
            value: 3,
            label: '员工'
          }];

        default:
          return [];
      }
    }); //下拉框value变化时触发

    var handleChange = function handleChange(val) {
      emit('handleChange', val);
      emit('update:modelValue', val);
    }; //多选模式下移除 tag 时触发


    var onRemoveTag = function onRemoveTag(val) {
      emit('onRemoveTag', val);
    }; //文本赋值


    var handleText = function handleText(val) {
      var onoff = isArray(val) ? true : false;
      var active = [];

      if (onoff) {
        active = list.value.filter(function (item) {
          return val.includes(item.value);
        });
      } else {
        active = list.value.filter(function (item) {
          return val == item.value;
        });
      }

      var str = '';

      if (active.length) {
        str = onoff ? active.map(function (item) {
          return item.label;
        }).join('-') : active[0].label;
      } else {
        str = '-';
      }

      return str;
    };

    return {
      list: list,
      handleChange: handleChange,
      handleText: handleText,
      onRemoveTag: onRemoveTag
    };
  }
});