export const tableColumns = [
    {
        title: '',
        align: 'center',
        type: 'selection',
        width: 60,
        visible: true,
    },
    {
        title: '序号',
        align: 'center',
        type: 'index',
        slots: { customRender: 'indexNumber' },
        width: 60,
        visible: true,
    },
    {
        title: '设备名称',
        align: 'center',
        width: 160,
        key: 'name',
        visible: true,
    },
    {
        title: 'SN号',
        align: 'center',
        width: 160,
        key: 'sn',
        visible: true,
    },
    {
        title: 'IMEI号',
        align: 'center',
        width: 160,
        key: 'imei',
        visible: true,
    },
    {
        title: '权限类型',
        align: 'center',
        width: 160,
        key: 'permissionType',
        slots: { customRender: 'permissionType' },
        visible: true,
    },
    {
        title: '人员名称',
        align: 'center',
        width: 120,
        key: 'userName',
        visible: true,
    },
    {
        title: '开锁时间',
        align: 'center',
        width: 120,
        key: 'openDoorTime',
        visible: true,
    },
    {
        title: '设备ID',
        align: 'center',
        width: 160,
        key: 'id',
        visible: false,
    },
    {
        title: '开门记录',
        align: 'center',
        width: 160,
        key: 'openId',
        visible: false,
    },
    {
        title: '安装地址',
        align: 'center',
        width: 160,
        key: 'installAddress',
        visible: false,
    },
    {
        title: '开锁方式',
        align: 'center',
        width: 160,
        key: 'openDoorWay',
        slots: { customRender: 'openDoorWay' },
        visible: true,
    },
    {
        title: '操作',
        width: 120,
        align: 'center',
        visible: true,
        slots: {
            customRender: 'operation',
        },
    },
];
