export const tableColumns = [
    {
        title: '',
        align: 'center',
        type: 'selection',
        width: 60,
        visible: true,
    },
    {
        title: '序号',
        align: 'center',
        type: 'index',
        slots: { customRender: 'indexNumber' },
        width: 60,
        visible: true,
    },
    {
        title: '邀请人',
        align: 'center',
        width: 120,
        key: 'inviteName',
        visible: true,
    },
    {
        title: '受邀人',
        align: 'center',
        width: 120,
        key: 'userName',
        visible: true,
    },
    {
        title: '受邀人手机',
        align: 'center',
        width: 140,
        key: 'phone',
        visible: true,
    },
    {
        title: '所属部门',
        align: 'center',
        width: 160,
        key: 'parentOrgName',
        visible: true,
    },
    {
        title: '职位',
        align: 'center',
        slots: { customRender: 'position' },
        width: 80,
        key: 'position',
        visible: true,
    },
    {
        title: '类别',
        align: 'center',
        width: 80,
        key: 'phone',
        slots: { customRender: 'level' },
        visible: false,
    },
    {
        title: '设备名称',
        align: 'center',
        width: 120,
        key: 'name',
        visible: false,
    },
    {
        title: '设备SN号',
        align: 'center',
        width: 120,
        key: 'deviceIds',
        visible: false,
    },
    {
        title: '创建时间',
        align: 'center',
        width: 150,
        key: 'createTime',
        visible: true,
    },
    {
        title: '邀请开始时间',
        align: 'center',
        width: 150,
        key: 'startTime',
        visible: true,
    },
    {
        title: '邀请结束时间',
        align: 'center',
        width: 150,
        key: 'endTime',
        visible: true,
    },
    {
        title: '操作',
        fixed: 'right',
        align: 'center',
        slots: { customRender: 'operate' },
        width: 140,
        key: 'imei',
        visible: true,
    },
];
