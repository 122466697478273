export const tableColumns = [
    {
        title: '',
        align: 'center',
        type: 'selection',
        width: 60,
        visible: true,
    },
    {
        title: '序号',
        align: 'center',
        type: 'index',
        slots: { customRender: 'indexNumber' },
        width: 60,
        visible: true,
    },
    {
        title: '人员名称',
        align: 'center',
        width: 160,
        key: 'userName',
        visible: true,
    },
    {
        title: '所属公司/部门',
        align: 'center',
        width: 160,
        key: 'parentNameRecord',
        visible: true,
    },
    {
        title: '职位',
        align: 'center',
        slots: { customRender: 'position' },
        width: 120,
        key: 'position',
        visible: true,
    },
    {
        title: '性别',
        align: 'center',
        slots: { customRender: 'gender' },
        width: 120,
        key: 'gender',
        visible: true,
    },
    {
        title: '电话',
        align: 'center',
        width: 120,
        key: 'phone',
        visible: true,
    },
    {
        title: '权限类型',
        align: 'center',
        width: 120,
        key: 'phone',
        slots: { customRender: 'level' },
        visible: false,
    },
    {
        title: '创建时间',
        align: 'center',
        width: 120,
        key: 'createTime',
        visible: true,
    },
    {
        title: '操作',
        fixed: 'right',
        align: 'center',
        slots: { customRender: 'operate' },
        width: 140,
        key: 'imei',
        visible: true,
    },
    {
        title: '用户id',
        align: 'center',
        width: 120,
        key: 'id',
        visible: false,
    },
    {
        title: '组织id',
        align: 'center',
        width: 120,
        key: 'orgId',
        visible: false,
    },
    {
        title: '顶级组织id',
        align: 'center',
        width: 120,
        key: 'topOrgId',
        visible: false,
    },
    {
        title: '所在部门信息',
        align: 'center',
        width: 120,
        key: 'parentNameRecord',
        visible: false,
    },
];
