import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue";
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  return !_ctx.isText ? (_openBlock(), _createBlock(_component_el_select, {
    key: 0,
    "model-value": _ctx.modelValue,
    size: _ctx.size,
    placeholder: _ctx.text,
    clearable: _ctx.clearable,
    onRemoveTag: _ctx.onRemoveTag,
    filterable: _ctx.filterable,
    multiple: _ctx.multiple,
    onChange: _ctx.handleChange,
    disabled: _ctx.isDisabled
  }, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, function (item, index) {
        return _openBlock(), _createBlock(_component_el_option, {
          key: index,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]);
      }), 128))];
    }),
    _: 1
  }, 8, ["model-value", "size", "placeholder", "clearable", "onRemoveTag", "filterable", "multiple", "onChange", "disabled"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.handleText(_ctx.modelValue)), 1));
}