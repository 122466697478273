import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4f95710e"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "InvitationRecord"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_BasicTemplateTablePage = _resolveComponent("BasicTemplateTablePage");

  var _component_InvitationInfo = _resolveComponent("InvitationInfo");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BasicTemplateTablePage, {
    loading: _ctx.loading,
    tableData: _ctx.tableData,
    tableColumns: _ctx.tableColumns,
    total: _ctx.total,
    pageSize: _ctx.pageSize,
    current: _ctx.current,
    onCurrentChange: _ctx.currentChange,
    onSelectionChange: _ctx.selectionChange
  }, {
    indexNumber: _withCtx(function (_ref) {
      var $index = _ref.$index;
      return [_createTextVNode(_toDisplayString((_ctx.current - 1) * _ctx.pageSize + ($index + 1)), 1)];
    }),
    position: _withCtx(function (_ref2) {
      var row = _ref2.row;
      return [_createTextVNode(_toDisplayString(_ctx.positionType(row)), 1)];
    }),
    operate: _withCtx(function (_ref3) {
      var row = _ref3.row;
      return [_createElementVNode("span", {
        class: "spanItem",
        onClick: function onClick($event) {
          return _ctx.openDrawerInfo(row);
        }
      }, "详情", 8, _hoisted_2), _createElementVNode("span", {
        class: "spanItem",
        onClick: function onClick($event) {
          return _ctx.onDeleteVisitorOrder(row);
        }
      }, "删除", 8, _hoisted_3)];
    }),
    _: 1
  }, 8, ["loading", "tableData", "tableColumns", "total", "pageSize", "current", "onCurrentChange", "onSelectionChange"]), _createVNode(_component_InvitationInfo, {
    visible: _ctx.drawerInfo.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.drawerInfo.visible = $event;
    }),
    title: _ctx.drawerInfo.title,
    infoData: _ctx.drawerInfo.infoData
  }, null, 8, ["visible", "title", "infoData"])]);
}